import { getStyledElement, getMediaStyles } from "@/helpers";
import { IonImg } from "@ionic/react";

export const Img = getStyledElement(IonImg)({
  variants: {
    round: (theme) => ({
      backgroundColor: theme.colors.grayF4Prx,
      objectFit: "contain",
      borderRadius: "50%",
      ...getMediaStyles({
        minHeight: theme.sizes.doctorListImageHeight,
        minWidth: theme.sizes.doctorListImageHeight,
        width: theme.sizes.doctorListImageHeight,
        height: theme.sizes.doctorListImageHeight
      })
    }),
    fullWidth: (theme) => ({
      backgroundColor: theme.colors.grayF4Prx,
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }),
    fullWidthCover: (theme) => ({
      backgroundColor: theme.colors.grayF4Prx,
      width: "100%",
      height: "100%",
      objectFit: "cover"
    })
  }
});
